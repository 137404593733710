// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f8ff;
  color: #333;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #1e3a8a;
  padding: 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}

.page {
  padding: 20px;
}

h1 {
  color: #1e3a8a;
}

a {
  color: #0d6efd;
}

a:hover {
  color: #0a58ca;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,gCAAgC;EAChC,SAAS;EACT,UAAU;EACV,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* App.css */\nbody {\n  font-family: 'Arial', sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: #f0f8ff;\n  color: #333;\n}\n\n.App {\n  text-align: center;\n}\n\n.App-header {\n  background-color: #1e3a8a;\n  padding: 20px;\n}\n\nnav ul {\n  list-style-type: none;\n  padding: 0;\n}\n\nnav ul li {\n  display: inline;\n  margin: 0 10px;\n}\n\nnav ul li a {\n  color: #ffffff;\n  text-decoration: none;\n  font-weight: bold;\n}\n\nnav ul li a:hover {\n  text-decoration: underline;\n}\n\n.page {\n  padding: 20px;\n}\n\nh1 {\n  color: #1e3a8a;\n}\n\na {\n  color: #0d6efd;\n}\n\na:hover {\n  color: #0a58ca;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
