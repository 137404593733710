import React from 'react';
import TableauEmbed from '../components/tableauembed';

function Home() {
  return (
    <div className="page">
      <h1>Welcome to Jay-Freeman.com</h1>
      <p>  </p>
      <TableauEmbed />
      
    </div>
  );
}

export default Home;
