import React, { useEffect } from 'react';

const TableauEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
    script.async = true;

    const container = document.getElementById('tableauViz');
    container.appendChild(script);
  }, []);

  return (
    <div style={{ width: '100%', height: '600px', overflow: 'hidden' }}>
      <div 
        className="tableauPlaceholder" 
        id="tableauViz" 
        style={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <noscript>
          <img
            alt="Video Games - Estimated Hours Played 1989 - Current"
            src="https://public.tableau.com/static/images/Vi/VideoGames_17376134571950/Bubble/1_rss.png"
            style={{ border: 'none' }}
          />
        </noscript>
        <object className="tableauViz" style={{ display: 'none' }}>
          <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
          <param name="embed_code_version" value="3" />
          <param name="site_root" value="" />
          <param name="name" value="VideoGames_17376134571950/Bubble" />
          <param name="tabs" value="no" />
          <param name="toolbar" value="yes" />
          <param name="static_image" value="https://public.tableau.com/static/images/Vi/VideoGames_17376134571950/Bubble/1.png" />
          <param name="animate_transition" value="yes" />
          <param name="display_static_image" value="yes" />
          <param name="display_spinner" value="yes" />
          <param name="display_overlay" value="yes" />
          <param name="display_count" value="yes" />
          <param name="language" value="en-US" />
          <param name="filter" value="publish=yes" />
        </object>
      </div>
    </div>
  );
};

export default TableauEmbed;
