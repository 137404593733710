import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Career from './pages/Career';
import Contact from './pages/Contact';
import './App.css';

function RedirectToHugo() {
  React.useEffect(() => {
    window.location.href = 'https://storage.googleapis.com/jay-freeman-12n12/index.html';
  }, []);
  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/projects">Projects</Link></li>
              <li><Link to="/career">Career</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/12n12">12 in 12</Link></li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/12n12" element={<RedirectToHugo />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
